.s-btn{
    border-radius: 100%;
    padding: 2px;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 0px;
}
.s-btn2{
    border-radius: 100%;
    padding: 2px;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0px;

}
.Btn-group{
  position: relative;
    width: 100%;
    top: -300px;
    z-index: 1;
}


.Btn-group-2{
  position: relative;
  width: 100%;
    bottom: 270px;
    z-index: 1;
}
.Btn-group-3{
  position: relative;
  width: 100%;
    bottom: 270px;
    z-index: 1;
}


@media screen and (max-width: 1450px) {
 
    .Btn-group{
      position: relative;
      width: 100%;
      bottom: 100px;
      z-index: 1;
    }
    .Btn-group-3{
      display: none;
      position: relative;
      width: 100%;
      bottom: 110px;
      z-index: 1;
      
    }
    .Btn-group-2{
       display: none;
       position: relative;
      width: 100%;
      bottom: 1300px;
      z-index: 1;
     }
}
@media screen and (min-width: 1600px) {
 
  .Btn-group{
    position: relative;
    width: 100%;
    bottom: 100px;
    z-index: 1;
  }
}
@media screen and (min-width: 1200px) {
 
  .Btn-group{
    position: relative;
    width: 100%;
    bottom: 100px;
    z-index: 1;
  }
}


@media screen and (max-width: 600px) {
    .Btn-group-3{
       display: none;
       
     }
     .Btn-group-2{
        display: none;
      }
      .Btn-group{
        display: none;
      }
  }
