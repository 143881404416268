@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amethysta&family=Jost:ital,wght@0,100..900;1,100..900&family=Manrope:wght@200..800&display=swap');
body{
    background-color: #FAF8F0 !important;
}
.mainbackground{
    background-color: #FAF8F0 !important;
overflow: hidden;
}
.navbarmain{
    background-color: #FAF8F0 !important;
border-bottom: 1px solid rgba(0, 0, 0, 0.373);
}
.navbartext a {
    color: rgba(0, 0, 0, 0.63);
    font-family: "jost";
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .navbartext a:active,
  .navbartext a:hover,
  .navbartext a:focus {
    color: rgba(0, 0, 0, 0.866); /* Change this to your desired dark color */
  }
  
  /* If you have a class for active items */
  .navbartext a.active {
    color: rgb(0, 0, 0); /* Change this to your desired dark color */
  }
  .searchitems{
display: flex;
align-items: center;
  }
.searchitems input{
    border: none;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 2px solid black;
}
.searchitems button{
   border: none;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 2px solid black;
}


    .secondban{
background-image: url("./Images/ban-2.png");
background-repeat: no-repeat;
background-size: cover;
display: flex;
justify-content: end;
align-items: center;
flex-direction: column;
text-align: center;
color: white;
  }
  .product {
    position: relative;
    overflow: hidden;
}
.product p{
    font-family: "manrope";
    color: black;
    font-size: 14px;
    font-weight: 600;
}
.product span{
    font-family: "jost";
    color: black;
    font-size: 12px;
    /* margin-bottom: 20px; */
}
.likeicon {
    position: absolute;
    right: 30px;
    top: -40px; /* Initially hide the icon above the view */
    transition: top 0.5s ease; /* Smooth transition effect */
}

.product:hover .likeicon {
    top: 20px; /* Move the icon to the desired position when hovering */
}
  .shippingbox{
  text-align: center;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 20px;

  }
  .dicountbox{
border: 4px dotted black;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
padding: 10px;
  }
  .dicountbox p:nth-child(1){
    font-size: 20px;
    font-weight: 500;
  padding-right: 20px;
font-family: "manrope";
color: black;
  }
  .dicountbox p:nth-child(2){
  font-family: "jost";
    border-radius: 30px;
    background-color: black;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
text-transform: uppercase;
letter-spacing: 1px;
  }
  .dicountbox p:nth-child(3){
    padding-left: 10px;
    font-size: 14px;
    font-family: "manrope";
    color: black;
    }

    .productbanner2{
background-image: url("./Images//ban-3.png");
background-repeat: no-repeat;
background-size: cover;
border-radius: 20px;
display: flex;
justify-content: end;
align-items: center;
flex-direction: column;
text-align: center;
    }
    .productbanner{
background-image: url("./Images//ban-4.png");
background-repeat: no-repeat;
background-size: cover;
border-radius: 20px;
display: flex;
justify-content: end;
align-items: center;
flex-direction: column;
text-align: center;
width: 100%;
    }

    .productbannercontent p:nth-child(1){
     font-size: 14px;
     font-weight: 600;
    font-family: "jost";
    padding-bottom: 20px;
    }
    .productbannercontent p:nth-child(2){
     font-family: "Amethysta";
        font-size:32px;
    padding-bottom: 20px;
    }
    .productbannercontent p:nth-child(3){
      font-family: "Amethysta";
        font-size:15px;
    padding-bottom: 20px;
    }
     .productbannercontent a{
font-family: "jost";
font-size: 15px;
color: #060606;
text-decoration: underline !important;
    padding-bottom: 15px;

     }
    .neck-ban{
        position: relative;
        background-image: url("./Images/neckles.png");
        background-repeat: no-repeat;
        background-size: cover;
height: 400px;
    }

    .neck-bancontent{
    text-align: start;
        color: #ffffff;
        padding: 20px;
    }
    .neck-bancontent p:nth-child(1){
        font-family: "jost";
        padding-top: 20px;
        font-size: 17px;
        color: white;
        text-transform: uppercase;
        padding-bottom: 20px;

    }
    .neck-bancontent p:nth-child(2){
        font-family: "Amethysta";
        font-size: 46px;
        color: white;
         padding-bottom: 50px;
    }
     .neck-bancontent a{
        font-family: "jost";
        font-size: 20px;
        color: rgb(160, 160, 160);
        text-decoration: underline;
        position: absolute;
        bottom: -110;
    }

    .testimonials h5{
font-family: "jost";
font-size: 24px;
font-weight: 700;
text-align: center;
    }

    .banner5{
    height: 440px;
    padding: 30px;
       background-image: url("./Images/ban-5.png");
       background-repeat: no-repeat;
       background-position: cover;
       color: white;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
 font-family: "jost";

    }
    .banner5 h6{
font-family: "manrope";
font-size: 28px;
padding-bottom: 20px;
    }
     .banner5 p{
 font-family: "jost";
font-size: 16px;
color: white;
text-align: center;
margin-bottom: 20px;
     }
.banner5 button {
    border: 2px solid white;
    padding: 10px 30px;
    font-family: "jost";
    transition: background-color 0.9s ease, color 0.6s ease; /* Add this line for smooth transition */
}

.banner5 button:hover {
    background-color: white;
    color: black;
}

    .mainfooter{
        background-color: black;
        
    }
    .footercontent{
        color: white;
        padding: 30px;
    }
    .footercontent ul li:nth-child(1){
        font-size: 14px;
        font-weight: 900;
        font-family: "manrope";
    color: #ffffff;

    }
      .footercontent ul li{
      text-align: start;
      font-size: 15px;
  font-family: "jost";
    color: #7A7A7A;
padding-bottom: 20px;
      }
    .copyrights{
        border-top: 0.5px solid rgba(210, 210, 210, 0.164);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        color: white !important;
        font-family: "jost";
        text-transform: uppercase;
        }
.contact{
    padding: 30px;
    color: white;
}
.contact label{

    font-size: 10px;
    color: #7A7A7A;
    padding-bottom: 20px;
    padding-top: 10px;
    text-align: start;
    font-family: "manrope";
    letter-spacing: 2px;
}
.contact p{
    font-size: 10px;
    color: #7A7A7A;
    padding-bottom: 20px;
    padding-top: 10px;
}
.contact input{
    background-color: black;
    width: 100%;
    border:1px solid white;
    padding: 15px;
}
.contact button{
    background-color: black;
    border:1px solid white;
    padding: 15px;
}
.contact2 p{
   font-family: "manrope";
   font-size: 14px;
   color: white;
   font-weight: 700;
}
.contact3{
   font-family: "manrope";
   font-size: 26px;
   color: white;
   font-weight: 700;
   white-space: nowrap;
}
.testibox{
background-color: black;
margin: 0px 10px;
padding: 15px;
border-radius: 20px;
color: white;
}
.testibox p{
color: white;

}
.firstban{
height: 600px;
}
.mySwiper2{
height: 600px;
}

.mySwiper2 .swiper-pagination-bullet {
    position: relative;
    background-color: #000000 !important; /* Background color of the inactive dot */
     padding: 2px;
  width: 2px;; /* Adjust dot size */
  height: 2px;
    border-radius: 50%; /* Ensures the dot is circular */
    margin: 0 18px !important; /* Add margin to create space between dots */
}

.mySwiper2 .swiper-pagination-bullet::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgb(0, 0, 0) !important; /* Border around the dot */
    border-radius: 50%; /* Ensures the circle is circular */ /* Adjust circle size */
    padding: 6px;
    box-sizing: border-box;
}
.mySwiper2 .swiper-pagination-bullet-active {
    position: relative;
    background-color: #ffffff !important; /* Background color of the active dot */
     padding: 2px;
  width: 2px;; /* Adjust dot size */
  height: 2px;
    border-radius: 50%; /* Ensures the dot is circular */
    margin: 0 18px !important; /* Add margin to create space between dots */
}

.mySwiper2 .swiper-pagination-bullet-active::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ;
    border: 2px solid rgb(255, 255, 255) !important; /* Border around the dot */
    border-radius: 50%; /* Ensures the circle is circular */ /* Adjust circle size */
    padding: 6px;
    box-sizing: border-box;
}





.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  background-image: url("./Images//ban-1.png");
  background-size: cover;
  color: white;
}
.slidecontent{
text-align: start;
height: 100%;
color: white;
display: flex;
justify-content: center;
padding: 80px;
align-items: start;
flex-direction: column;
}

.swiper-slide .title {
font-weight: 800px;
font-family: "manrope" !important;
  font-size: 48px;
  font-weight: 900;
}

.swiper-slide .subtitle {
font-family: "jost";
  font-size: 16px;
width: 50%;
padding-top: 20px;
}

.swiper-slide .text {
margin-top: 20px;
background-color: white;
padding: 10px 30px;
color: black;
margin-top: 10px;
font-size: 16px;
font-family: "jost";

}

/* Add this to your CSS file */
.swiper-pagination-bullet {
  padding: 5px; /* Adjust the padding value as needed */
}


@media screen and (max-width:700px) {
        .copyrights{
        border-top: 0.5px solid rgba(210, 210, 210, 0.164);
        display: block;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        color: white !important;
        font-family: "jost";
        text-transform: uppercase;
        }
        .navbartext a{
color: black;
display: none;
}
.searchitems{
display: none;
}
.slidecontent{

padding: 20px;

}
.swiper-slide .title {
font-weight: 800px;
font-family: "Manrope";
  font-size: 32px;
  font-weight: 800;
  width: 100%;
}

.swiper-slide .subtitle {
font-family: "jost";
  font-size: 13px;
width: 80%;

}

.swiper-slide .text {
background-color: white;
padding: 10px 30px;
color: black;
margin-top: 10px;
font-size: 8px;
font-family: "jost";

}
  .dicountbox{
border: 4px dotted black;
border-radius: 10px;
display: block;
justify-content: center;
align-items: center;
padding: 10px;
text-align: center;
  }
      .neck-ban{
        position: relative;
        background-image: url("./Images/neckles.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position:right;
height: 150px;
    }
        .neck-bancontent{
        color: #ffffff;
        padding: 10px;
    }
    .neck-bancontent p:nth-child(1){
        font-family: "jost";
        padding-top: 20px;
        font-size: 10px;
        color: white;
        text-transform: uppercase;
        padding-bottom: 10px;

    }
    .neck-bancontent p:nth-child(2){
        font-family: "Amethysta";
        font-size: 23px;
        color: white;
         padding-bottom: 20px;
    }
     .neck-bancontent a{
        font-family: "jost";
        font-size: 10px;
        color: rgb(160, 160, 160);
        text-decoration: underline;
        position: absolute;
        bottom: -110;
    }
}